import React from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import Layout from '../../components/Layout';
import { SectionInstagramPosts } from '../../components/InstagramPosts';
import { parseYoutubeLink } from '../../utils/common';

import iconTick from '../../assets/images/svg/tick.svg';

import '../../assets/styles/pages/tefl-iberia-graduates-page.scss';

const Graduates = ({ data }) => {
  const allPrismicGraduates = data.allPrismicGraduates.edges;
  const firstGraduate = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned === true
  );
  const otherGraduates = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned !== true
  );

  const allPrismicInstagramPosts = data.allPrismicInstagramPosts.edges;

  return (
    <Layout title={{ id: 'graduates.seo.title' }} description={{ id: 'graduates.seo.description' }}>
      <div className="tefl-iberia-graduates-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="block_l c-benefit-list ">
                  <h1 className="c-title-42 first-section__title">
                    <FormattedMessage id="graduates.sec1.title" />
                  </h1>
                  <p className="c-text-18 first-section__description">
                    <FormattedMessage id="graduates.sec1.description" />
                  </p>
                  <h4>
                    <FormattedMessage id="graduates.sec1.benTitle" />
                  </h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="graduates.sec1.ben1" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="graduates.sec1.ben2" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="graduates.sec1.ben3" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {firstGraduate.map(({ node }) => (
                  <div className="block_r" key={node.id}>
                    <div className="block_r__video">
                      <iframe
                        width="560"
                        height="315"
                        src={parseYoutubeLink(node?.data?.video?.embed_url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                    {/* <h3 className="block_r__title">{node?.data?.title?.text}</h3> */}
                    {/* <p className="block_r__description">{node?.data?.description?.text}</p> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="row">
              {otherGraduates.map(({ node }) => (
                <div className="col-lg-4 col-sm-6 col-12" key={node.id}>
                  <div className="review-item">
                    <div className="review-item__video">
                      <iframe
                        width="560"
                        height="315"
                        src={parseYoutubeLink(node?.data?.video?.embed_url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                    <h3 className="c-text-16 review-item__title">{node?.data?.title?.text}</h3>
                    <p className="c-text-14 review-item__description">
                      {node?.data?.description?.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <SectionInstagramPosts
          title={() => <FormattedMessage id="graduates.sec2.modalTitle" />}
          description={() => <FormattedMessage id="graduates.sec2.modalDescription" />}
          allPrismicInstagramPosts={allPrismicInstagramPosts}
        />
      </div>
    </Layout>
  );
};

// export const SectionInstagramPosts = ({ allPrismicInstagramPosts }) => {
//   const LIMIT_IG_POSTS = 6;

//   const [selectedPostId, setSelectedPostId] = useState(null);
//   const [isShowingMore, setIsShowingMore] = useState(false);

//   const showPostDetails = selectedPostId !== null;
//   const postDetails = allPrismicInstagramPosts.find(({ node }) => node.id === selectedPostId)?.node
//     ?.data;

//   // console.log(allPrismicInstagramPosts, postDetails);
//   return (
//     <section className="third-section c-section-py">
//       <div className="container">
//         <div className="row">
//           <div className="third-section__titles">
//             <h2 className="c-title-34">
//               Follow us on social media and keep up to date with student Life
//             </h2>
//             <p className="c-text-18">Keep up to date with our students on Instagram</p>
//           </div>

//           {allPrismicInstagramPosts
//             .slice(0, isShowingMore ? allPrismicInstagramPosts.length : LIMIT_IG_POSTS)
//             .map(
//               ({
//                 node: {
//                   id,
//                   data: { title, description, tags, date, image, instagram_link },
//                 },
//               }) => {
//                 return (
//                   <div key={id} className="col-lg-4 col-md-6 col-12 mb-4">
//                     <div className="instagram-card">
//                       <div className="card__head">
//                         <a
//                           href={'https://www.instagram.com/tefliberia/'}
//                           target="_blank"
//                           className="head__wrapper"
//                         >
//                           <img
//                             className="card__authorImg"
//                             src={imageLogoIg}
//                             width={36}
//                             height={36}
//                             alt="TEFL Iberia @tefliberia"
//                           />
//                           <div className="card__authorData">
//                             <h3 className="card__authorName">TEFL Iberia @tefliberia</h3>
//                             <span className="card__authorDate">{date}</span>
//                           </div>
//                           <IconInst className="card__iconInstagram" width={35} height={35} />
//                         </a>
//                       </div>
//                       <div onClick={() => setSelectedPostId(id)} className="card__content">
//                         {image?.url && (
//                           <img
//                             src={image.url}
//                             alt={title?.text || 'tefl instagram'}
//                             className="card__thumb"
//                             width={400}
//                           />
//                         )}
//                         <div className="card__body">
//                           {title?.text && <p className="card__description">{title.text}</p>}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               }
//             )}
//           <div className="third-section__load-more-btn ">
//             {allPrismicInstagramPosts?.length > LIMIT_IG_POSTS && (
//               <button
//                 type="button"
//                 className="c-btn c-btn--red-border mr-4"
//                 onClick={() => setIsShowingMore(!isShowingMore)}
//               >
//                 {!isShowingMore ? 'Show More' : 'Show Less'}
//               </button>
//             )}
//             <a
//               className="c-btn c-btn--red"
//               target="_blank"
//               href="https://www.instagram.com/tefliberia/"
//             >
//               Follow Us
//             </a>
//           </div>

//           <div className={`backdrop ${showPostDetails ? 'visible' : ''}`}>
//             <div className="modal">
//               <div className="post">
//                 <a
//                   className="post__thumb"
//                   href={postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <img
//                     src={postDetails?.image?.url}
//                     alt={postDetails?.title?.text ?? 'TEFL Iberia'}
//                   />
//                 </a>
//                 <div className="post__body">
//                   <div className="post__head">
//                     <a
//                       href={'https://www.instagram.com/tefliberia/'}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <span className="post__title">TEFL Iberia @tefliberia</span>
//                     </a>
//                     <a
//                       href={
//                         postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'
//                       }
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <IconInst className="post__iconInstagram" width={35} height={35} />
//                     </a>
//                   </div>
//                   {postDetails?.title?.text && (
//                     <a
//                       href={
//                         postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'
//                       }
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <p className="post__title mb-2">{postDetails.title.text}</p>
//                     </a>
//                   )}
//                   {postDetails?.description?.text && (
//                     <p className="post__description mb-3">{postDetails.description.text}</p>
//                   )}
//                   {postDetails?.tags && (
//                     <p className="post__tags">
//                       {postDetails?.tags.split(' ').map((tag) => (
//                         <a
//                           className="post__tag"
//                           href={`https://www.instagram.com/explore/tags/${tag.slice(1)}/`}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           {tag}
//                         </a>
//                       ))}
//                     </p>
//                   )}
//                   {postDetails?.date && (
//                     <p className="post__date">
//                       <IconClock /> {postDetails.date}
//                     </p>
//                   )}
//                 </div>
//               </div>
//             </div>
//             <button onClick={() => setSelectedPostId(null)} className="modal__close">
//               <IconClose />
//             </button>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

export const query = graphql`
  query {
    allPrismicGraduates(sort: { order: DESC, fields: last_publication_date }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            video {
              embed_url
            }
            pinned
          }
        }
      }
    }
    allPrismicInstagramPosts(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date(formatString: "DD MMMM")
            image {
              url
            }
            description {
              text
            }
            tags
            instagram_link {
              url
            }
          }
        }
      }
    }
  }
`;

export default Graduates;
