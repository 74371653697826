import React, { useEffect, useState } from 'react';
import IconInst from '../assets/images/svg/icons8-instagram.inline.svg';
import IconClose from '../assets/images/svg/icon-close-modal.inline.svg';
import IconClock from '../assets/images/svg/icons8-clock.inline.svg';
// import IconWhatsApp from '../assets/images/svg/whatsapp.inline.svg';
import imageLogoIg from '../assets/images/graduates/tefl-ig-logo.jpg';

import '../assets/styles/components/instagram-posts.scss';

export const SectionInstagramPosts = ({
  allPrismicInstagramPosts,
  title = null,
  description = null,
}) => {
  const LIMIT_IG_POSTS = 6;

  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isShowingMore, setIsShowingMore] = useState(false);

  const showPostDetails = selectedPostId !== null;
  const postDetails = allPrismicInstagramPosts.find(({ node }) => node.id === selectedPostId)?.node
    ?.data;

  useEffect(() => {
    const handleCloseModal = (event) => {
      if (event.code === 'Escape') setSelectedPostId(null);
    };

    window.addEventListener('keydown', handleCloseModal);

    return () => window.removeEventListener('keydown', handleCloseModal);
  }, []);

  return (
    <section className="instagram-posts c-section-py">
      <div className="container">
        <div className="row">
          <div className="instagram-posts__titles">
            {title && (
              <h2 className="c-title-34">
                {title()} {/* Follow us on social media and keep up to date with student Life */}
              </h2>
            )}
            {description && (
              <p className="c-text-18">
                {description()}
                {/*Keep up to date with our students on Instagram */}
              </p>
            )}
          </div>

          {allPrismicInstagramPosts
            .slice(0, isShowingMore ? allPrismicInstagramPosts.length : LIMIT_IG_POSTS)
            .map(
              ({
                node: {
                  id,
                  data: { title, description, tags, date, image, instagram_link },
                },
              }) => {
                return (
                  <div key={id} className="col-lg-4 col-md-6 col-12 mb-4">
                    <div className="instagram-card">
                      <div className="card__head">
                        <a
                          href={'https://www.instagram.com/tefliberia/'}
                          target="_blank"
                          className="head__wrapper"
                        >
                          <img
                            className="card__authorImg"
                            src={imageLogoIg}
                            width={36}
                            height={36}
                            alt="TEFL Iberia @tefliberia"
                          />
                          <div className="card__authorData">
                            <h3 className="card__authorName">TEFL Iberia @tefliberia</h3>
                            <span className="card__authorDate">{date}</span>
                          </div>
                          <IconInst className="card__iconInstagram" width={35} height={35} />
                        </a>
                      </div>
                      <div onClick={() => setSelectedPostId(id)} className="card__content">
                        {image?.url && (
                          <img
                            src={image.url}
                            alt={title?.text || 'tefl instagram'}
                            className="card__thumb"
                            width={400}
                          />
                        )}
                        <div className="card__body">
                          {title?.text && <p className="card__description">{title.text}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          <div className="instagram-posts__load-more-btn ">
            {allPrismicInstagramPosts?.length > LIMIT_IG_POSTS && (
              <button
                type="button"
                className="c-btn c-btn--red-border mr-4"
                onClick={() => setIsShowingMore(!isShowingMore)}
              >
                {!isShowingMore ? 'Show More' : 'Show Less'}
              </button>
            )}
            <a
              className="c-btn c-btn--red"
              target="_blank"
              href="https://www.instagram.com/tefliberia/"
            >
              Follow Us
            </a>
          </div>

          <div className={`backdrop ${showPostDetails ? 'visible' : ''}`}>
            <div className="modal">
              <div className="post">
                <a
                  className="post__thumb"
                  href={postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={postDetails?.image?.url}
                    alt={postDetails?.title?.text ?? 'TEFL Iberia'}
                  />
                </a>
                <div className="post__body">
                  <div className="post__head">
                    <a
                      href={'https://www.instagram.com/tefliberia/'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="post__title">TEFL Iberia @tefliberia</span>
                    </a>
                    <a
                      href={
                        postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconInst className="post__iconInstagram" width={35} height={35} />
                    </a>
                  </div>
                  {postDetails?.title?.text && (
                    <a
                      href={
                        postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="post__title mb-2">{postDetails.title.text}</p>
                    </a>
                  )}
                  {postDetails?.description?.text && (
                    <p className="post__description mb-3">{postDetails.description.text}</p>
                  )}
                  {postDetails?.tags && (
                    <p className="post__tags">
                      {postDetails?.tags.split(' ').map((tag) => (
                        <a
                          className="post__tag"
                          href={`https://www.instagram.com/explore/tags/${tag.slice(1)}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {tag}
                        </a>
                      ))}
                    </p>
                  )}
                  {postDetails?.date && (
                    <p className="post__date">
                      <IconClock /> {postDetails.date}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <button onClick={() => setSelectedPostId(null)} className="modal__close">
              <IconClose />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
